.topImage {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.container {
  margin: 0 auto;
  margin-top: 20px;
  width: 450px;
  height: 100%;
  text-align: center;
  /*font-size: 20px;*/
}

.fortySix {
  color: #17bee8;
  font-weight: bold;
}

.evalForm {
  display: flex;
  flex-direction: column;
}

.radioGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.questionLabel {
  font-weight: bold;
  margin-top: 20px;
}

.dates {
  margin-top: 20px;
}

.dates input {
  margin-left: 5px;
  margin-right: 5px;
}

.radioGroup div {
  padding: 10px 10px 0 10px;
}

.radioGroup label {
  display: block;
}

.submit {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 800px) {
  .topImage {
    height: 100px;
  }

  .container {
    width: 80%;
    font-size: 16px;
  }

  .dates {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}