.topImage {
    height: 150px;
    width: 100%;
    object-fit: cover;
}

.description {
    margin: 0 auto;
    width: 60%;
    line-height: 1.8;
    margin-top: 20px;
}

@media screen and (max-width: 800px) {
    .topImage {
        height: 100px;
    }
}

