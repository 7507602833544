.home {
	width: 100%;
	height: 100%;
	background: red;
}

.carouselText {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: lightgray;
  z-index: 8;
  width: auto;
  text-align: center;
  top: 70%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  font-size: 70px;
  background-color: rgba(50, 50, 50, 0.4);
}

@media screen and (min-width: 801px) and (max-width: 1200px) {
  .carouselText {
    font-size: 50px
  }
}

@media screen and (max-width: 800px) {
	.carouselText {
    font-size: 25px
	}
}