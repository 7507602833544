.header {  
	width: 100%		
}

@media screen and (max-width: 649px) {
	.header {  
	  height: auto;
		padding-bottom: 10px;
  }
	
	.headerContainer {
		display: flex;
		width: auto;
  	padding: 0 20px;
		flex-direction: column;
	}
  .titleContainer {
		flex-direction: column;		
	}
	.navbar {
		flex-direction: column;		
	}
	.menuContainer {
		flex-direction: column;		
		height: auto;
	}
	.subtitle {
	  text-align: center
  }
	.menuCalendar {
	  margin-left: 30%;
    margin-right: 30%;
	}
	
	.menu {
		padding: 4px 10px;
	}
}

@media screen and (min-width: 650px) and (max-width: 1200px) {
	.header {  
	  height: 75px;
  }	
	.headerContainer {
		display: flex;
		width: auto;
		padding: 0 20px;
	}
	.menuContainer {
		height: 75px;
	}
	.menu {
		padding: 0 10px;
	}
	.menuCalendar {
		height: 75px;
	}
}

@media screen and (min-width: 1200px) {
	.header {  
	  height: 75px;
  }	
	.headerContainer {
		display: flex;
		width: 1200px;
		margin: 0 auto;
		position: relative;	
	}
	.menuContainer {
		height: 75px;
	}
	.menu {
		padding: 0 10px;
	}
	.menuCalendar {
		height: 75px;
	}
}

.titleContainer {
	flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  padding: 9px;
}

.titleContainer a {
    text-decoration: none;
    outline: none;
	  color: #292929
}

.titleContainer h3 {
	font-weight: bold;
  margin: 0px;
	font-size: 30px;
}

.titleContainer h3 span {
	color: #17bee8
}

.subtitle {
	display: block;
	font-size: 14px;
}

.navbar {
  flex-basis: 50%;
	display: flex;
}

.menuContainer {
	display: flex;  
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
}

.menu {  
  color: bisque;
  display: flex;
  justify-content: center;
  align-items: center;  
	cursor: pointer;
}

.menuCalendar {
	background: #17bee8;
	color: white !important;	
}

.menuLink {
/*  font-family: 'Roboto', sans-serif;*/
  text-decoration: none;
  outline: none;
	color: #292929;
	font-weight: 500;
	font-size: 15px;
}

.menu a:hover  {
	color: #17bee8
}

.language {
	display: flex;
	flex-direction: column;
	margin-left: 15px;
	margin-top: 4px;
}

.language a {
	cursor: pointer;
	height: 24px;
	text-decoration: none;
	outline: none;
	/*color: #17bee8;*/
	font-weight: 500;
	font-size: 13px;
	color: black;
}

.language a:hover  {
	color: #17bee8
}

.body {
	padding-bottom: 20px;
}

@media screen and (max-width: 649px) {
  .language {
		flex-direction: row;
		margin: 5px auto;
	}
	.languageItem {
		margin: 0 15px 0 15px;
	}
}

